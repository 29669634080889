<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <tgl-breadcrumbs :items="breadcrumbs" />
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <dynamic-dashboard-form :data-background-color="getTheme">
      </dynamic-dashboard-form>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import DynamicDashboardForm from "@/components/DynamicDashboard/Crud/DynamicDashboardForm";
import { mapGetters } from "vuex";
export default {
  components: {
    TglBreadcrumbs,
    DynamicDashboardForm,
  },
  computed: {
    ...mapGetters(["getTheme"]),
    breadcrumbs() {
      if (this.$route.params.dashboard) {
        return [
          {
            label: "Tableros",
            disabled: false,
            to: { name: "workspace", params: { tab: 0 } },
          },
          {
            label: `Editar tablero ${this.$route.params.dashboard}`,
            disabled: true,
            to: { name: "workspace", params: { tab: 0 } },
          },
        ];
      } else {
        return [
          {
            label: "Tableros",
            disabled: false,
            to: { name: "workspace", params: { tab: 0 } },
          },
          {
            label: "Crear tablero",
            disabled: true,
            to: { name: "workspace", params: { tab: 0 } },
          },
        ];
      }
    },
  },
};
</script>